:root {
  /* Màu chủ đạo */
  --primary: #2563EB;          /* Xanh dương chính */
  --primary-dark: #1E40AF;     /* Xanh dương đậm */
  --primary-light: #60A5FA;    /* Xanh dương nhạt */
  
  /* Gradient */
  --primary-gradient: linear-gradient(45deg, var(--primary), var(--primary-light));
  
  /* Màu trung tính */
  --text-dark: #1F2937;        /* Màu chữ đậm */
  --text-light: #4B5563;       /* Màu chữ nhạt */
  --gray-50: #F9FAFB;          /* Background nhạt */
  --gray-100: #F3F4F6;         /* Border nhạt */
  --gray-200: #E5E7EB;         /* Border đậm */
  --white: #FFFFFF;

  /* Shadow */
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);

  /* Các giá trị khác */
  --max-width: 1200px;
  --border-radius: 8px;
  --border-radius-lg: 12px;
}

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 24px;
}

/* Banner Section */
.banner {
  position: relative;
  padding: 100px 0;
  background: linear-gradient(135deg, var(--gray-50) 0%, var(--white) 100%);
  overflow: hidden;
}

.banner-content {
  position: relative;
  z-index: 2;
}

.banner-title {
  font-size: 56px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  margin-bottom: 24px !important;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--text-dark) !important;
}

.banner-title .highlight {
  display: block;
  color: var(--text-dark);
  -webkit-text-fill-color: var(--text-dark);
}

.banner-desc {
  font-size: 20px;
  line-height: 1.6;
  color: var(--text-light);
  margin-bottom: 32px;
  max-width: 540px;
}

.banner-features {
  margin-bottom: 40px;
}

.banner-features ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.banner-features li {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: var(--text-light);
}

.banner-features .anticon {
  color: var(--primary);
  font-size: 20px;
}

.banner-buttons {
  display: flex;
  gap: 20px;
}

.btn-gradient {
  background: var(--primary-gradient) !important;
  border: none !important;
  height: 56px !important;
  padding: 0 36px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  box-shadow: 0 10px 20px rgba(40, 81, 231, 0.2) !important;
  color: var(--white) !important;
}

.btn-outline {
  border: 2px solid var(--primary) !important;
  color: var(--primary) !important;
  height: 56px !important;
  padding: 0 36px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.banner-image-wrapper {
  position: relative;
  z-index: 2;
}

.banner-image {
  width: 100%;
  height: auto;
  transform: perspective(1000px) rotateY(-15deg);
  transition: transform 0.6s ease;
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.1));
}

.banner-image:hover {
  transform: perspective(1000px) rotateY(-5deg);
}

/* Stats Section */
.stats-section {
  position: relative;
  margin-top: -80px;
  margin-bottom: 80px;
  z-index: 3;
}

.stats-section .container {
  background: var(--white);
  border-radius: var(--border-radius);
  padding: 48px;
  box-shadow: var(--shadow-lg);
}

.stat-item {
  text-align: center;
  padding: 20px;
}

.stat-number {
  font-size: 36px;
  font-weight: 700;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
  color: var(--primary) !important;
}

.stat-label {
  font-size: 16px;
  color: var(--text-light);
  font-weight: 500;
}

/* Templates Section */
.templates-section {
  padding: 100px 0;
  background: var(--gray-50);
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.template-card {
  background: var(--white);
  border-radius: calc(var(--border-radius) - 4px);
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: var(--shadow-sm);
}

.template-card:hover {
  transform: translateY(-8px);
  box-shadow: var(--shadow-lg);
  border-color: var(--primary-light);
}

.template-card img {
  height: 240px;
  width: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.template-card:hover img {
  transform: scale(1.05);
}

/* Testimonials Section */
.testimonial-card {
  padding: 32px;
  height: 100%;
  transition: all 0.3s ease;
  background: var(--white);
  border: 1px solid var(--gray-100);
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
  border-color: var(--primary-light);
}

.testimonial-rating {
  margin-bottom: 20px;
}

.star-icon {
  color: #FCD34D;  /* Vàng cho sao đánh giá */
  font-size: 20px;
  margin-right: 4px;
}

.testimonial-quote {
  font-size: 16px;
  color: var(--text-light);
  margin-bottom: 24px;
  line-height: 1.6;
}

.testimonial-profile {
  display: flex;
  align-items: center;
  gap: 16px;
}

.testimonial-info {
  flex: 1;
}

.testimonial-info h5 {
  margin-bottom: 4px !important;
}

/* CTA Section */
.cta-section {
  position: relative;
  padding: 120px 0;
  background: var(--primary-gradient);
  color: var(--white);
  text-align: center;
}

.cta-title {
  color: white !important;
  font-size: 42px !important;
  margin-bottom: 24px !important;
}

.cta-description {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 18px;
  margin-bottom: 40px;
}

.cta-button {
  height: 54px !important;
  padding: 0 48px !important;
  font-size: 18px !important;
  border-radius: 27px !important;
  background: var(--white) !important;
  color: var(--primary) !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg) !important;
  background: #f8f9fa !important;
}

/* Animations */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .banner {
    padding: 60px 0;
  }

  .banner-title {
    font-size: 40px !important;
  }

  .banner-features ul {
    grid-template-columns: 1fr;
  }

  .banner-buttons {
    flex-direction: column;
  }

  .stats-section {
    margin-top: -40px;
  }

  .stats-section .container {
    padding: 24px;
  }

  .template-card img {
    height: 200px;
  }

  .testimonial-item {
    padding: 20px;
    margin: 10px;
  }
}

/* Benefits Section */
.benefits-section {
  padding: 80px 0;
  background: var(--white);
}

.benefit-card {
  text-align: center;
  padding: 32px;
  height: 100%;
  transition: all 0.3s ease;
  background: var(--white);
  border: 1px solid var(--gray-100);
}

.benefit-card:hover {
  transform: translateY(-8px);
  box-shadow: var(--shadow-md);
  border-color: var(--primary-light);
}

.benefit-icon {
  font-size: 48px;
  color: var(--primary);
  margin-bottom: 24px;
}

/* Hover Effects */
.btn-gradient:hover,
.cta-button:hover {
  box-shadow: var(--shadow-lg) !important;
  transform: translateY(-2px);
}

.template-card:hover,
.testimonial-card:hover,
.benefit-card:hover {
  border-color: var(--primary-light);
  box-shadow: var(--shadow-md);
}

/* Contact Modal */
.contact-modal .contact-info {
  padding: 20px;
  text-align: center;
  font-size: 18px;
}

.contact-info p {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.contact-info a {
  color: var(--primary);
  font-weight: 600;
}

.contact-info .anticon {
  font-size: 24px;
  color: var(--primary);
}
