.footer {
  background: var(--text-dark);
  color: var(--white);
  padding: 60px 0 20px;
}

.footer-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  margin-bottom: 30px;
}

.footer-title {
  color: var(--white) !important;
  margin-bottom: 20px !important;
  font-size: 18px !important;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  margin-bottom: 12px;
}

.footer-list a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-list a:hover {
  color: var(--primary-light);
}

.contact-info {
  color: rgba(255, 255, 255, 0.8);
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
}

.social-links {
  display: flex;
  gap: 16px;
  margin-top: 20px;
}

.social-links a {
  color: var(--white);
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--primary-light);
}

.footer-bottom {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 768px) {
  .footer {
    padding: 40px 0 20px;
  }
  
  .footer-section {
    margin-bottom: 30px;
  }
}
