.aboutpage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  background: #fff;
}

/* Profile Section */
.profile-section {
  text-align: center;
  margin-bottom: 48px;
}

.profile-section .ant-avatar {
  border: 4px solid #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}

.profile-section h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 16px 0 8px;
  color: #1a1f36;
}

.profile-section .ant-typography strong {
  color: #4f566b;
  font-size: 18px;
}

/* About Section */
.about-section {
  margin-bottom: 48px;
}

.about-section .ant-typography {
  font-size: 16px;
  line-height: 1.8;
  color: #4f566b;
  margin-bottom: 16px;
}

.about-section strong {
  color: #1a1f36;
  background: #f7fafc;
  padding: 2px 8px;
  border-radius: 4px;
}

/* Education Section */
.education-section {
  margin-bottom: 48px;
}

.education-section h3 {
  font-size: 24px;
  color: #1a1f36;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f0f2f5;
}

.education-section strong {
  display: block;
  font-size: 18px;
  color: #1a1f36;
  margin-bottom: 8px;
}

/* Work Experience Section */
.work-section {
  margin-bottom: 48px;
}

.work-section h3 {
  font-size: 24px;
  color: #1a1f36;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f0f2f5;
}

.work-section h4 {
  font-size: 20px;
  color: #2851E7;
  margin-bottom: 16px;
}

.work-section .ant-typography {
  margin-bottom: 12px;
}

.work-section strong {
  color: #1a1f36;
  margin-right: 8px;
}

/* Download Section */
.download-section {
  text-align: center;
  margin-top: 40px;
  padding: 32px;
  background: #f7fafc;
  border-radius: 16px;
}

.download-section h5 {
  font-size: 20px;
  color: #1a1f36;
  margin-bottom: 16px;
}

.download-section img {
  transition: transform 0.3s ease;
}

.download-section img:hover {
  transform: translateY(-4px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .aboutpage {
    padding: 40px 16px;
  }

  .profile-section .ant-avatar {
    width: 96px !important;
    height: 96px !important;
  }

  .profile-section h3 {
    font-size: 24px;
  }

  .about-section .ant-typography,
  .education-section .ant-typography,
  .work-section .ant-typography {
    font-size: 15px;
  }
}
